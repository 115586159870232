import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom/dist";
import Logo from "../../assets/images/Logo/Logo.png";
import ContactHeader from "./ContactHeader";

const NavbarData = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About US",
    link: "/about-us",
  },
  {
    title: "Contact US",
    link: "/contact-us",
  },
  {
    title: "Privacy Policy",
    link: "/terms-and-conditions",
  },
];

const TopNavBanner = ({ className }) => {
  let location = window.location;

  const [expanded, setExpanded] = useState(false);

  const handleNavToggle = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const _active = (linkname) => {
    return location?.pathname === linkname;
  };

  return (
    <div>
      <ContactHeader />
      <div className={`top-nav-bar ${className}`}>
        <Navbar bg="" expand="lg" variant="dark">
          <Container>
            <Navbar.Brand href="/">
              <img loading="lazy"src={Logo} alt="produtc-logo" />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={handleNavToggle}
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav
                className="ms-auto"
                defaultActiveKey="/"
                onClick={handleNavToggle}
              >
                {NavbarData.map(({ title, link }, index) => (
                  <Nav.Item key={index} className={"p-3"}>
                    <Link
                      to={link}
                      className={_active(link) ? "active" : ""}
                      onClick={handleNavToggle}
                    >
                      {title}
                    </Link>
                  </Nav.Item>
                ))}

              </Nav>
                <Link to="http://www.web.seemyclouds.net">
                  <Button className="btn-theme-default btn-contact-us">
                    Login
                  </Button>
                </Link>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default TopNavBanner;
