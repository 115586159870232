import React from "react";
import { Col, Row } from "react-bootstrap";
import { EmailWhiteIcon, PhoneWhiteIcon } from "../../utils/svgicons";
import linkedIn from "../../assets/images/Home/contact header/linkedIn.svg";
import facebook from "../../assets/images/Home/contact header/facebook.svg";
import Insta from "../../assets/images/Home/contact header/insta.svg";
import youtube from "../../assets/images/Home/contact header/youtube.svg";
import tweeter from "../../assets/images/Home/contact header/tweeter.svg";

const ContactHeader = () => (
  <div className="contact-us-header">
    <div className="container">
      <Row>
        <Col xs={12} md={6}>
          <div className="contact-list">
            <div className="email_phone">
              <EmailWhiteIcon />
              <div>Talk To Us  (844) 983-0416</div>
            </div>
            <div className="email_phone">
              <PhoneWhiteIcon />
              <div>Email: info@seemycloud.com</div>
            </div>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="social_icons d-flex">
            <a
              href="https://www.linkedin.com/company/see-my-clouds"
              target="_blank"
            >
              <img
                loading="lazy"
                src={linkedIn}
                alt=""
                className="object-fit-contain image-fluid cursor"
              />
            </a>
            <a href="https://www.facebook.com/seemycloud" target="_blank">
              <img
                loading="lazy"
                src={facebook}
                alt=""
                className="object-fit-contain image-fluid cursor"
              />
            </a>
            <a
              href="#"
              // target="_blank"
            >
              <img
                loading="lazy"
                src={tweeter}
                alt=""
                className="object-fit-contain image-fluid cursor"
              />
            </a>
            <a href="https://www.instagram.com/seemy.clouds" target="_blank">
              <img
                loading="lazy"
                src={Insta}
                alt=""
                className="object-fit-contain image-fluid cursor"
              />
            </a>
            <a
              href="#"
              // target="_blank"
            >
              <img
                loading="lazy"
                src={youtube}
                alt=""
                className="object-fit-contain image-fluid cursor"
              />
            </a>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default ContactHeader;
