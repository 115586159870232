import React from "react";

import { AddHeader } from "../components/Home/AddHeader";
import ApplicationFeature from "../components/Home/ApplicationFeature";
import CreatePostFeature from "../components/Home/CreataPostFeature";
import MyProfileImage from "../components/Home/MyProfileImage";
import FollowRequest from "../components/Home/FollowReq";
import SingleChat from "../components/Home/Singlechat";
import HomeSlider from "../components/Home/userFlowSlider";
import ReadyToGetStarted from "../components/Home/ReadyToGetStarted";
import Gallery from "../components/Home/Gallery";
import Faqs from "../components/Home/Faqs";
import ContentSection from "../components/Home/ContentSection";

const HomePage = () => {
  return (
    <div className="home-page">
      <div className="add-header">
        <AddHeader />
      </div>
      <div className="application-feature">
        <Gallery />
      </div>
      <div className="application-feature">
      <ContentSection/>
      </div>
      <div className="application-feature">
        <ApplicationFeature />
      </div>
      <div className="create-post-application">
        <CreatePostFeature />
      </div>
      <div className="create-post-application my-profile-image">
        <MyProfileImage />
      </div>
      <div className="create-post-application follow-req">
        <FollowRequest />
      </div>
      <div className="create-post-application my-profile-image single-chat">
        <SingleChat />
      </div>
      <div className="user-flow-info mt-5">
        <HomeSlider />
      </div>
      <div className="ready-to-get-started mt-5">
        <ReadyToGetStarted />
      </div>
      <div className="application-feature">
        <Faqs />
      </div>
    </div>
  );
};

export { HomePage };