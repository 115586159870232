import React from "react";
import { Col, Row } from "react-bootstrap";
import MobileScreen from "../../assets/images/Home/ApplicationFeature/mobile_screen.jpg";
import { CubeIcon, StarIcon, TrangleIcon } from "../../utils/svgicons";

const records = [
  {
    title: "Create a Post",
    des: "Craft content, engage followers, and enhance visibility. Seamlessly integrate media, hashtags, and mentions while optimizing for interaction. Whether posting text, images, or videos, the feature adapts to your needs with personalized filters. Share a cloud! ",
    icon: <StarIcon />,
  },
  {
    title: "My Profile & Add Pictures ",
    des: "Designed for cloud enthusiasts, personalize your profile and effortlessly upload stunning cloud photos from any device. Connect with fellow cloud lovers, receive feedback, and curate your social presence with ease.",
    icon: <CubeIcon />,
  },
  {
    title: "Follow Request",
    des: "Connect with like-minded cloud enthusiasts using our Follow Request feature. Easily send and receive follow requests, allowing you to build a personalized network of cloud lovers. This feature ensures your privacy by giving you control over who can view your cloud photo collection and interact with your posts.",
    icon: <TrangleIcon />,
  },
  {
    title: "Single Chat & Group Chat ",
    des: "With Single Chat, enjoy one-on-one conversations where you can share cloud pictures, discuss weather patterns, or simply bond over your love for the sky. Group Chat in our cloud photo sharing app allows you to join cloud-watching communities, exchange ideas, and explore different cloud formations together ",
    icon: <StarIcon />,
  },
];

const ApplicationFeature = () => {
  return (
    <div className="application_feature_component container">
      <Row>
        <Col xs={12}>
          <div className="headers">
            {/* <div className="title">See My Cloud</div> */}
            <div 
            // className="title_2"
            className="title">Application Features</div>
          </div>
        </Col>
      </Row>

      <Row className="mt-4 mobile_section">
        <Col xs={12} sm={6} md={6} lg={4} className="description">
          {records.slice(0,2).map((v, index) => (
            <div key={index} className="d-flex gap-4">
              <div className="d-flex flex-column gap-2">
                <h4 className="text-end">{v.title}</h4>
                <div className="text-end details">{v.des}</div>
              </div>
              <div>{v.icon}</div>
            </div>
          ))}
        </Col>

        <Col lg={4} className="d-lg-block d-none">
          <div className="d-flex align-items-center justify-content-center image_section">
            <img loading="lazy"src={MobileScreen} alt="" />
          </div>
        </Col>

        <Col xs={12} sm={6} md={6} lg={4} className="description">
          {records.slice(2,4).map((v, index) => (
            <div key={index} className="d-flex gap-4">
              <div>{v.icon}</div>

              <div className="d-flex flex-column gap-2">
                <h4 className="text-start">{v.title}</h4>
                <div className="text-start details">{v.des}</div>
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default ApplicationFeature;
