"use client";

import { useState } from "react";
import { GoDash } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { BsSearch } from "react-icons/bs";

function Faqs() {
  //   let { data, isLoading } = useGetFaqsQuestionsQuery();

  const data = [
    {
      id: 1,
      question:
        "Rerum quia fugiat nesciunt non quasi quis.\nAut consequatur corporis molestiae accusantium a beatae repellat.\nAccusantium cupiditate debitis et.",
      answer:
        "Ea omnis qui deserunt atque officia.\nEius earum praesentium iste ut neque nulla sit aspernatur.\nAsperiores pariatur iusto fuga et ex et.\nQui autem voluptas.\nQuia et cumque veritatis officia harum doloremque facilis quae.",
    },
    {
      id: 2,
      question:
        "Et debitis incidunt laudantium praesentium maxime ab.\nConsequatur illum placeat est accusantium aspernatur quibusdam.",
      answer:
        "Eligendi tenetur eum fugiat nihil labore.\nMolestias velit voluptatem dolorem debitis ullam ab in.\nVelit voluptatem qui ducimus vero asperiores quis recusandae culpa.\nSapiente quis quia commodi.",
    },
    {
      id: 3,
      question:
        "Aut repudiandae reprehenderit id velit cum magni in mollitia.\nDolore maxime sed mollitia et at.\nSunt velit quam labore et totam facilis est rerum.\nOmnis cum dolor fugiat distinctio aut exercitationem tempore eveniet omnis.\nQuasi voluptatem impedit similique vel non dolores assumenda laboriosam iure.",
      answer:
        "Sit maiores laudantium temporibus libero vitae fugit numquam magnam quam.\nIusto repudiandae ea ad tempore eius illum.\nFuga nulla ea incidunt minima est autem.\nEa illo architecto.\nNon numquam velit laborum cupiditate nobis.",
    },
    {
      id: 4,
      question:
        "Ea magni asperiores sit enim.\nOptio cupiditate excepturi.\nEx ab nobis asperiores inventore ut recusandae.\nSimilique inventore et rerum ab.",
      answer:
        "Tempore consequatur aut unde voluptas dolorem quaerat consequatur.",
    },
    {
      id: 5,
      question:
        "Fugit sint tempora ea nostrum inventore voluptas debitis est sed.\nAut quidem sit pariatur necessitatibus dolorem atque consequatur.\nConsequatur iste molestias ipsa veritatis aliquam ab.\nEt laboriosam quo magni vitae laborum eaque quia animi.",
      answer:
        "Placeat facilis dolorem est.\nPariatur quibusdam nesciunt non id.\nBeatae quas cum odio a.",
    },
    {
      id: 6,
      question:
        "Dolore id eius aspernatur voluptas atque reprehenderit quod dolores provident.\nExercitationem eos corrupti facilis cupiditate ut in.\nBeatae sit repudiandae ea quaerat illo possimus.\nEt alias et facilis eos et sit.\nRepellat tenetur corporis laboriosam voluptatum labore eaque magni.",
      answer:
        "Ratione natus optio saepe illum architecto aut eligendi.\nLaborum ipsam ea laborum aut eligendi nostrum molestiae.\nEaque saepe nam deserunt nihil.\nTempora consequatur sit.",
    },
    {
      id: 7,
      question:
        "Qui voluptas quis praesentium et.\nVeniam asperiores dolore sit excepturi quasi perspiciatis atque nam.\nOdio est aut incidunt consequatur.\nHic voluptatum qui harum.",
      answer:
        "Accusamus odio quos non officiis et.\nOfficia non animi fugiat suscipit ipsa dicta quia.\nRatione repellendus porro voluptatem hic enim ea dicta.\nSequi consequatur dolores non cum nesciunt ut voluptates fugiat.\nVoluptatum reiciendis iure voluptatem dolorem quia magni suscipit totam corporis.",
    },
    {
      id: 8,
      question:
        "Quia maiores optio odit sit asperiores accusamus.\nDoloremque dicta et.\nQui sit est quasi rerum eveniet vitae velit totam ipsa.\nPorro ea ut consectetur nesciunt veniam vitae veritatis quis ea.",
      answer:
        "Sequi aperiam quod et qui aut aut magni.\nQuia corrupti veniam voluptatem in.\nRem beatae eaque labore.\nError libero vitae officiis.\nQuaerat molestiae optio et voluptatem esse ducimus repudiandae.",
    },
    {
      id: 9,
      question:
        "Neque voluptatem autem dolorum ut et aut.\nNemo sed sit.\nRatione aliquam quo molestias distinctio perferendis.",
      answer:
        "Quis magni consequatur rerum praesentium et totam inventore eius eligendi.\nAssumenda quo deserunt dolorum quisquam et distinctio.\nQui in voluptatum quam quas iure quia.\nSoluta expedita excepturi architecto sint omnis dolore magnam dolore est.",
    },
    {
      id: 10,
      question:
        "Voluptas sed omnis similique sint voluptatibus quos ut sed.\nIure sequi eos ipsum ut id facere consequuntur minus.\nSit omnis minima laborum voluptas voluptatem exercitationem reiciendis et.\nTempore vel occaecati neque dolor.",
      answer:
        "Accusantium delectus soluta laudantium.\nError nemo ad nam fuga rerum quia veniam in inventore.\nAmet rerum veniam iste dolores qui ratione.",
    },
  ];
  const [questions, setQuestions] = useState([
    {
      id: 1,
      question:
        "Rerum quia fugiat nesciunt non quasi quis.\nAut consequatur corporis molestiae accusantium a beatae repellat.\nAccusantium cupiditate debitis et.",
      answer:
        "Ea omnis qui deserunt atque officia.\nEius earum praesentium iste ut neque nulla sit aspernatur.\nAsperiores pariatur iusto fuga et ex et.\nQui autem voluptas.\nQuia et cumque veritatis officia harum doloremque facilis quae.",
    },
    {
      id: 2,
      question:
        "Et debitis incidunt laudantium praesentium maxime ab.\nConsequatur illum placeat est accusantium aspernatur quibusdam.",
      answer:
        "Eligendi tenetur eum fugiat nihil labore.\nMolestias velit voluptatem dolorem debitis ullam ab in.\nVelit voluptatem qui ducimus vero asperiores quis recusandae culpa.\nSapiente quis quia commodi.",
    },
    {
      id: 3,
      question:
        "Aut repudiandae reprehenderit id velit cum magni in mollitia.\nDolore maxime sed mollitia et at.\nSunt velit quam labore et totam facilis est rerum.\nOmnis cum dolor fugiat distinctio aut exercitationem tempore eveniet omnis.\nQuasi voluptatem impedit similique vel non dolores assumenda laboriosam iure.",
      answer:
        "Sit maiores laudantium temporibus libero vitae fugit numquam magnam quam.\nIusto repudiandae ea ad tempore eius illum.\nFuga nulla ea incidunt minima est autem.\nEa illo architecto.\nNon numquam velit laborum cupiditate nobis.",
    },
    {
      id: 4,
      question:
        "Ea magni asperiores sit enim.\nOptio cupiditate excepturi.\nEx ab nobis asperiores inventore ut recusandae.\nSimilique inventore et rerum ab.",
      answer:
        "Tempore consequatur aut unde voluptas dolorem quaerat consequatur.",
    },
    {
      id: 5,
      question:
        "Fugit sint tempora ea nostrum inventore voluptas debitis est sed.\nAut quidem sit pariatur necessitatibus dolorem atque consequatur.\nConsequatur iste molestias ipsa veritatis aliquam ab.\nEt laboriosam quo magni vitae laborum eaque quia animi.",
      answer:
        "Placeat facilis dolorem est.\nPariatur quibusdam nesciunt non id.\nBeatae quas cum odio a.",
    },
    {
      id: 6,
      question:
        "Dolore id eius aspernatur voluptas atque reprehenderit quod dolores provident.\nExercitationem eos corrupti facilis cupiditate ut in.\nBeatae sit repudiandae ea quaerat illo possimus.\nEt alias et facilis eos et sit.\nRepellat tenetur corporis laboriosam voluptatum labore eaque magni.",
      answer:
        "Ratione natus optio saepe illum architecto aut eligendi.\nLaborum ipsam ea laborum aut eligendi nostrum molestiae.\nEaque saepe nam deserunt nihil.\nTempora consequatur sit.",
    },
    {
      id: 7,
      question:
        "Qui voluptas quis praesentium et.\nVeniam asperiores dolore sit excepturi quasi perspiciatis atque nam.\nOdio est aut incidunt consequatur.\nHic voluptatum qui harum.",
      answer:
        "Accusamus odio quos non officiis et.\nOfficia non animi fugiat suscipit ipsa dicta quia.\nRatione repellendus porro voluptatem hic enim ea dicta.\nSequi consequatur dolores non cum nesciunt ut voluptates fugiat.\nVoluptatum reiciendis iure voluptatem dolorem quia magni suscipit totam corporis.",
    },
    {
      id: 8,
      question:
        "Quia maiores optio odit sit asperiores accusamus.\nDoloremque dicta et.\nQui sit est quasi rerum eveniet vitae velit totam ipsa.\nPorro ea ut consectetur nesciunt veniam vitae veritatis quis ea.",
      answer:
        "Sequi aperiam quod et qui aut aut magni.\nQuia corrupti veniam voluptatem in.\nRem beatae eaque labore.\nError libero vitae officiis.\nQuaerat molestiae optio et voluptatem esse ducimus repudiandae.",
    },
    {
      id: 9,
      question:
        "Neque voluptatem autem dolorum ut et aut.\nNemo sed sit.\nRatione aliquam quo molestias distinctio perferendis.",
      answer:
        "Quis magni consequatur rerum praesentium et totam inventore eius eligendi.\nAssumenda quo deserunt dolorum quisquam et distinctio.\nQui in voluptatum quam quas iure quia.\nSoluta expedita excepturi architecto sint omnis dolore magnam dolore est.",
    },
    {
      id: 10,
      question:
        "Voluptas sed omnis similique sint voluptatibus quos ut sed.\nIure sequi eos ipsum ut id facere consequuntur minus.\nSit omnis minima laborum voluptas voluptatem exercitationem reiciendis et.\nTempore vel occaecati neque dolor.",
      answer:
        "Accusantium delectus soluta laudantium.\nError nemo ad nam fuga rerum quia veniam in inventore.\nAmet rerum veniam iste dolores qui ratione.",
    },
  ]);

  const [showHide, setShowHide] = useState({});

  const handleOpenClose = async (index) => {
    try {
      const temp = { ...showHide };
      temp[index] = !temp[index];
      setShowHide(temp);
    } catch (e) {}
  };

  const handleSearchChange = (e) => {
    try {
      const value = e.target.value;
      if (value == "") {
        setQuestions(data);
        return;
      } else {
        let list = questions?.filter((v) =>
          v?.question?.toLowerCase()?.includes(value?.toLowerCase())
        );
        setQuestions(list);
      }
    } catch (e) {
      console.log(e);
    }
  };

  //   if (isLoading) {
  //     return (
  //       <div className="d-flex align-items-center justify-content-center min-vh-90 bg-white my-5 me-2 rounded">
  //         {/* <SpinnerLoading /> */}
  //       </div>
  //     );
  //   }

  return (
    <div className="bg-white rounded my-5 m-5 px-4 py-5 mx-auto container">
      <div className="row">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <h1 className="fs-2">
            <span className="color-lightblue fw-bold">Ask</span>{" "}
            <span className="color-grey fw-medium">Any Questions</span>
          </h1>
          <div
            className="w-25 d-flex align-items-center pe-3"
            style={{ background: "#FAFAFA"}}
          >
            <input
              type="search"
              placeholder={`Type your Question here ...`}
              aria-label="Search"
              className="form-input p-3 rounded-3 outline-none border-none bg-color-grey"
              style={{
                border: "none",
                outline: "none",
                background: "#FAFAFA",
                width: "100%",
              }}
              onChange={handleSearchChange}
            />
            <BsSearch className="order-1 color-lightblue fw-bold" />
          </div>
        </div>
        {!!questions?.length ? (
          questions.map(({ question, answer }, index) => {
            const condition = showHide[index];

            return (
              <div
                key={index}
                className={`col-12 py-4 px-5 my-2 select-none mt-4 ${
                  condition ? "shadow-border" : "border"
                }`}
                style={{
                  borderRadius: "1.5rem",
                  border: condition
                    ? "0.5px solid #136dc7"
                    : "0.5px solid lightgray",
                  boxShadow: condition
                    ? "0 4px 6px rgba(19, 109, 199, 0.5)"
                    : "none",
                }}
              >
                <div
                  className="d-flex align-items-center justify-content-between cursor-pointer"
                  onClick={() => handleOpenClose(index)}
                >
                  <div
                    className={`fs-6 ${
                      !condition ? "text-secondary" : "color-lightblue"
                    }`}
                  >
                    {question}
                  </div>
                  <div
                    className={`cursor-pointer ${
                      condition ? "rotate-180" : "rotate-45"
                    } transition-all`}
                  >
                    {!condition ? (
                      <FiPlus className="text-primary fw-bold" />
                    ) : (
                      <GoDash className="text-primary fw-bold" />
                    )}
                  </div>
                </div>
                {condition && (
                  <p className="animate-fade-down mt-4 w-75 fs-7">{answer}</p>
                )}
              </div>
            );
          })
        ) : (
          <p className="w-100 d-flex justify-content-center align-items-center h-10">Questions Not Found!</p>
        )}
      </div>
    </div>
  );
}

export default Faqs;
