import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Navigation } from './Routes/Navigations';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <Navigation />
      </BrowserRouter>
    </div>
  );
}

export default App;
