import React from "react";
import { Col, Row } from "react-bootstrap";
import mobileScreen from "../../assets/images/Home/createpost/createpostadd.png";
import GoogleStore from "../../assets/images/Home/TopHeader/google_store.png";
import AppleStore from "../../assets/images/Home/TopHeader/apple_store.png";

const CreatePostFeature = () => {
  return (
    <div className="create-post-feature-component container">
      <Row>
        <Col md={12} lg={6} className="add-image-column">
          <img
            src={mobileScreen}
            className="w-100 object-fit-contain image-fluid add-image"
          />
        </Col>
        <Col md={12} lg={6} className="d-flex align-items-center p-5">
          <div className="content">
            <h1>
              Create a Post <span>App Features</span>
            </h1>
            <p className="mt-5">
              Upload cloudy pictures that resonate with your friends and boost
              your visibility in the cloud photo sharing app community. Easily
              craft posts, add media, hashtags, and mentions to maximize
              engagement. Texts. Stunning cloud images. Videos. The platform
              adapts to your unique style with personalized filters and creative
              tools. Share your favorite cloud formation today and connect with
              the skies like never before!
            </p>
            <div className="d-flex justify-content-between w-100 mt-1 store_image">
              <div>
              <a href="https://apps.apple.com/us/app/see-my-clouds/id6578448718" target="_blank">
                <img
                  src={AppleStore}
                  alt=""
                  className="object-fit-contain image-fluid"
                />
                </a>
              </div>
              <div>
              <a href="https://play.google.com/store/apps/details?id=com.cloudprofsolution.seemyclouds" target="_blank">
                <img
                  src={GoogleStore}
                  alt=""
                  className="object-fit-contain image-fluid"
                />
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CreatePostFeature;
