import { Footer } from "../../components/Defaut/PageFooter";
import TopNavBanner from "../../components/Defaut/TopNavBar";

const GeneralLayout = ({ children, className }) => {
  return (
    <div className="general-layout">
      <TopNavBanner className={className} />
      <div className="children">{children}</div>
      <div className="footer-general mt-5">
        <Footer />
      </div>
    </div>
  );
};

export { GeneralLayout };
