import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

const TermsLayout = ({ data, active, setActive }) => {
  const handleScrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth"});
      setActive(id);
    }
  };

  //   const handleScroll = () => {
  //     const contentArea = document.querySelector(".column-content");
  //     const contentAreaRect = contentArea.getBoundingClientRect();

  //     if (
  //       contentAreaRect.top < window.innerHeight &&
  //       contentAreaRect.bottom >= 0
  //     ) {
  //       data?.forEach((dt) => {
  //         const section = document.getElementById(dt?.id);
  //         if (section) {
  //           const rect = section.getBoundingClientRect();
  //           if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
  //             setActive(dt?.id);
  //           }
  //         }
  //       });
  //     }
  //   };

  //   useEffect(() => {
  //     window.addEventListener("scroll", handleScroll);
  //     return () => {
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }, [data]);

  return (
    <>
      <div className="terms-layout">
        <Row className="list-select">
          <Col md={12} lg={4} className="column-list">
            <ul>
              {data?.map((dt) => (
                <>
                  <li
                    key={dt?.id}
                    className={`${active === dt?.id && "active"} cursor`}
                    onClick={() => handleScrollToSection(dt?.id)}
                  >
                    <span>{dt?.id}. </span>
                    {dt?.heading}
                  </li>
                </>
              ))}
            </ul>
          </Col>
          <Col md={12} lg={8} className="column-content">
            {data?.map((dt) => (
              <div className="content mt-5" id={dt?.id}>
                <h2 className="main-heading">
                  <span>{dt?.id}. </span>
                  {dt?.heading}
                </h2>
                {dt?.isSubheading ? (
                  <>
                    {dt?.body?.map((dt) => (
                      <div className="sub-heading-para mt-4">
                        <h3>{dt?.heading}</h3>
                        <p>{dt?.content}</p>
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="mt-4">{dt?.body}</p>
                )}
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default TermsLayout;
