import React from "react";
import { Col, Row } from "react-bootstrap";

const Gallery = () => {
  const  shared_images  = [
    "https://api.seemyclouds.net/173160104545745563.jpg",
    "https://api.seemyclouds.net/173160104546320662.jpg",
    "https://api.seemyclouds.net/173157038908089366.jpg",
    "https://api.seemyclouds.net/17315692500414186.jpg",
    "https://api.seemyclouds.net/173149730441399137.jpg",
    "https://api.seemyclouds.net/173149730441676916.jpg",
    "https://api.seemyclouds.net/173149730441890145.jpg",
    "https://api.seemyclouds.net/173148405797590357.jpg",
    "https://api.seemyclouds.net/173146722606081071.jpg",
    "https://api.seemyclouds.net/17314357376744138.jpg",
    "https://api.seemyclouds.net/173143573767574584.jpg",
    "https://api.seemyclouds.net/173143450905825391.jpg",
    "https://api.seemyclouds.net/173143450906052804.jpg",
    "https://api.seemyclouds.net/173143450906254349.jpg",
    "https://api.seemyclouds.net/173143450906462860.jpg",
    "https://api.seemyclouds.net/173142538104472238.jpg",
    "https://api.seemyclouds.net/173142530296729018.jpg"
  ];
  return (
    <Row className="p-4 post_container mt-3">
    <div className="headers mb-3">
        <h4 className="title">Cloud Pictures, Images and Stock Photos</h4>
        <p className="desc">Cloud Pictures, Images and Stock Photos</p>
    </div>
    
    <Col xs={12} sm={6} md={6} lg={4} className="post-images">
    <div
      className={`post-images image-${
        shared_images.length > 4 ? "more" : shared_images?.length
        // : post.image.length
      }`}
    >
      {shared_images?.length > 0 ? (
        shared_images?.map((fav, index) => (
          <img src={fav} alt={`Image ${index + 1}`} key={index} />
        ))
      ) : (
        <p
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: "200px" }}
        >
          Gallery Not Found
        </p>
      )}
    </div>
    </Col>
    </Row>
  );
};

export default Gallery;
