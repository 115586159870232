import React from "react";

const ContentSection = () => {
  return (
    <div className="bg-white rounded my-5 m-5 px-4 py-5 mx-auto container">
      <div className="row">
        <div className="headers mt-4">
          <h4 className="title">Lorem ipsum dolor sit amet.</h4>
          <p className="desc mt-2">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vitae
            ipsum animi doloremque, neque rerum nulla error vel. Repellat esse,
            quis, fugiat molestias quos minima deleniti fugit iusto officiis
            veritatis recusandae? Et provident obcaecati labore similique ipsa
            minima quo reprehenderit sequi natus, dolorem tempora sed sunt
            officia mollitia perferendis animi rerum veniam, rem illum.
            Exercitationem quam voluptates dolor rerum consectetur. Optio quo
            veritatis ratione voluptate doloribus harum vero maxime distinctio,
            minima, natus quia fuga iure perspiciatis molestias aperiam nobis.
            Officiis explicabo reprehenderit blanditiis dolorem quas voluptate
            obcaecati. Laudantium aliquam perferendis quas, vel nisi corporis
            minima, aperiam perspiciatis praesentium, expedita tempora modi.
          </p>
        </div>
        <div className="headers mt-4">
          <h4 className="title">Lorem ipsum dolor sit amet.</h4>
          <p className="desc mt-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt,
            aut asperiores? Harum similique voluptate doloremque ipsum dolorum
            nihil corrupti mollitia laudantium culpa quas minus id reprehenderit
            perspiciatis assumenda ipsa nostrum tenetur iusto omnis dicta, qui
            debitis! Asperiores assumenda ut nobis, minima sunt molestias
            dignissimos explicabo, dolorum veniam minus consectetur soluta
            maiores possimus perferendis, ea officiis adipisci a provident.
            Labore consequatur vero iure atque hic! Veritatis quo iure suscipit
            dignissimos sequi corrupti in eaque. Cum ipsam tempora nisi animi
            consequatur nam ab esse, sint, eligendi, quidem fugiat expedita
            nostrum neque placeat illum asperiores delectus eius alias
            praesentium repellendus. Distinctio, est vel?
          </p>
        </div>
        <div className="headers mt-4">
          <h4 className="title">Lorem ipsum dolor sit amet.</h4>
          <p className="desc mt-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore,
            officia laborum sunt vero libero sit vel. Alias labore, eum, sit
            autem quia harum iure doloremque ea dolores incidunt maiores
            praesentium expedita quisquam error. Velit obcaecati veniam eos,
            laboriosam laborum aspernatur architecto incidunt debitis dicta odio
            repudiandae. Odit at provident odio ab. Veniam iure pariatur
            dignissimos ad dolor architecto earum libero laborum quae quia at
            labore tempore perferendis impedit, reprehenderit in incidunt atque
            temporibus harum ipsa illum accusantium rerum. Officiis vel laborum
            facilis porro earum, fuga, aspernatur provident dignissimos vitae
            illo doloremque neque! Aliquam culpa eos explicabo dolorem quidem
            odio dicta?
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContentSection;
