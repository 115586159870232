import React from "react";

const AboutUs = () => {
  return (
    <div className="terms-and-condition container">
      <h1>About Us</h1>
      <div className="mt-3 mb-5">
        <p>
          Story behind See My Clouds - to create an app that empowers those who
          love to explore around cloudy pictures, skies, and everything natural
          specific to exploration.
        </p>
        <br />
        <p>
          A platform where you can share your favorite cloudy moments. Connect
          with other cloud enthusiasts. Form group chats, share posts,
          personalize your profile, and much more.
        </p>
        <br />
        <p>
          This app isn't just about capturing images of clouds; it's about
          creating a dynamic platform where students, teachers, scientists, and
          enthusiasts can identify, share, and discuss their findings.
        </p>
        <br />
        <p>
          Imagine students using the app to document cloud types during their
          coursework, engaging more deeply with the subject matter, and sharing
          their discoveries with classmates. And beyond the classroom, high
          school teachers could integrate the app into their environmental
          science lessons, bringing the wonders of the sky directly to students
        </p>
        <br />
        <p>
          With See My Clouds, we’re not just looking at clouds; we’re inviting
          you to be part of a movement that brings the beauty and complexity of
          our atmosphere into focus. Join us in capturing the sky and
          discovering the world above in a whole new way.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
