import React from "react";
import { Col, Row } from "react-bootstrap";
import mobileScreen from "../../assets/images/Home/Followreq/followreq.png";
import GoogleStore from "../../assets/images/Home/TopHeader/google_store.png";
import AppleStore from "../../assets/images/Home/TopHeader/apple_store.png";

const FollowRequest = () => {
  return (
    <div className="create-post-feature-component container">
      <Row>
        <Col md={12} lg={6} className="add-image-column">
          <img
            src={mobileScreen}
            className="w-100 object-fit-contain image-fluid add-image"
          />
        </Col>
        <Col md={12} lg={6} className="d-flex align-items-center p-5">
          <div className="content">
            <h1>
              Follow Request
              {/* <span>App Features</span> */}
            </h1>
            <p className="mt-5">
              Grow and connect with other cloudy picture lovers with our simple
              Follow Request feature. Easily send and accept requests to connect
              with other sky enthusiasts. You stay in control, deciding who can
              see your cloud photos and interact with your posts, making it a
              safe and personalized experience.
            </p>
            <div className="d-flex justify-content-between w-100 mt-1 store_image">
              <div>
              <a href="https://apps.apple.com/us/app/see-my-clouds/id6578448718" target="_blank">
                <img
                  src={AppleStore}
                  alt=""
                  className="object-fit-contain image-fluid"
                />
                </a>
              </div>
              <div>
              <a href="https://play.google.com/store/apps/details?id=com.cloudprofsolution.seemyclouds" target="_blank">
                <img
                  src={GoogleStore}
                  alt=""
                  className="object-fit-contain image-fluid"
                />
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FollowRequest;
