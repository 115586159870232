import React, { useState } from "react";
import TermsLayout from "../components/TermsAndCondition/Layout";
import ReadyToGetStarted from "../components/Home/ReadyToGetStarted";

const TermsAndCondition = () => {
  const [active, setActive] = useState(1);
  const termsData = [
    {
      id: 1,
      heading: "Introduction",
      body: "Welcome to SeeMyClouds! By accessing or using the SeeMyClouds mobile application, you agree to comply with and be bound by the following Terms and Conditions. Please read them carefully. If you do not agree with any part of these terms, you must not use the app.",
    },
    {
      id: 2,
      heading: "Use of the Application",
      isSubheading: true,
      body: [
        {
          id: 1,
          heading: "Eligibility",
          content:
            "You must be at least 13 years old to use the SeeMyClouds application. If you are under 18, you must have parental or guardian consent to use the app",
        },
        {
          id: 2,
          heading: "Account Registration",
          content:
            "You must be at least 13 years old to use the SeeMyClouds application. If you are under 18, you must have parental or guardian consent to use the app",
        },
        {
          id: 3,
          heading: "Content Sharing",
          content:
            "Users may upload, share, and interact with cloud imagery and related content. By uploading content, you grant SeeMyClouds a non-exclusive, royalty-free, worldwide, and perpetual license to use, display, and distribute your content on the platform.",
        },
        {
          id: 4,
          heading: "Prohibited Activities",
          content:
            "Users may upload, share, and interact with cloud imagery and related content. By uploading content, you grant SeeMyClouds a non-exclusive, royalty-free, worldwide, and perpetual license to use, display, and distribute your content on the platform.",
        },
      ],
    },
    {
      id: 3,
      heading: "User Conduct",
      isSubheading: true,
      body: [
        {
          id: 1,
          heading: "Respectful Interaction",
          content:
            "Users are expected to interact respectfully within the SeeMyClouds community.      Harassment, bullying, or any form of abusive behaviour will not be tolerated.",
        },
        {
          id: 2,
          heading: "Intellectual Property",
          content:
            "Users must respect the intellectual property rights of others. Do not upload content that you do not have the legal right to share.",
        },
      ],
    },
    {
      id: 4,
      heading: "Privacy and Data Security",
      isSubheading: true,
      body: [
        {
          id: 1,
          heading: "Respectful Interaction",
          content:
            "Users are expected to interact respectfully within the SeeMyClouds community.      Harassment, bullying, or any form of abusive behaviour will not be tolerated.",
        },
        {
          id: 2,
          heading: "Intellectual Property",
          content:
            "Users must respect the intellectual property rights of others. Do not upload content that you do not have the legal right to share.",
        },
      ],
    },
    {
      id: 5,
      heading: "Modifications to the Application",
      isSubheading: true,
      body: [
        {
          id: 1,
          heading: "Respectful Interaction",
          content:
            "Users are expected to interact respectfully within the SeeMyClouds community.      Harassment, bullying, or any form of abusive behaviour will not be tolerated.",
        },
        {
          id: 2,
          heading: "Intellectual Property",
          content:
            "Users must respect the intellectual property rights of others. Do not upload content that you do not have the legal right to share.",
        },
      ],
    },
    {
      id: 6,
      heading: "Limitation of Liability",
      isSubheading: true,
      body: [
        {
          id: 1,
          heading: "Respectful Interaction",
          content:
            "Users are expected to interact respectfully within the SeeMyClouds community.      Harassment, bullying, or any form of abusive behaviour will not be tolerated.",
        },
        {
          id: 2,
          heading: "Intellectual Property",
          content:
            "Users must respect the intellectual property rights of others. Do not upload content that you do not have the legal right to share.",
        },
      ],
    },
    {
      id: 7,
      heading: "Governing Law",
      isSubheading: true,
      body: [
        {
          id: 1,
          heading: "Respectful Interaction",
          content:
            "Users are expected to interact respectfully within the SeeMyClouds community.      Harassment, bullying, or any form of abusive behaviour will not be tolerated.",
        },
        {
          id: 2,
          heading: "Intellectual Property",
          content:
            "Users must respect the intellectual property rights of others. Do not upload content that you do not have the legal right to share.",
        },
      ],
    },
  ];
  return (
    <div className="terms-page container">
      <div className="heading ">
        <h1 className="page-heading">
          Terms and Conditions for SeeMyClouds Mobile Application
        </h1>
      </div>
      <div className="mt-5">
        <TermsLayout data={termsData} active={active} setActive={setActive} />
      </div>
      <div className="ready-to-get-started" style={{ marginTop: "14rem" }}>
        <ReadyToGetStarted />
      </div>
    </div>
  );
};

export { TermsAndCondition };
